<template>
  <p>
    <input
      :id="id"
      type="checkbox"
      class="checkbox"
      required
      :checked="modelValue"
      @input="$emit('update:modelValue', +$event.target.checked)"
    >
    <label
      :for="id"
      v-html="label"
    />
  </p>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
  .checkbox {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

.checkbox:focus {
  outline: 2px solid dodgerblue;
}

checkbox:focus:not(:focus-visible){
  outline: none;
}
</style>
