<template>
  <form
    v-if="showForm"
    @submit.prevent="register"
  >
    <BaseInput
      id="reg-email"
      v-model.trim="user.email"
      type="email"
      required
      autofocus
      autocomplete="email"
      :label="$t('ui.email')"
    />
    <BaseInput
      id="reg-pass"
      v-model.trim="user.pass"
      type="password"
      required
      autocomplete="new-password"
      :label="$t('ui.password')"
    />
    <BaseInput
      id="reg-pass-conf"
      v-model.trim="user.passConfirm"
      type="password"
      required
      autocomplete="new-password"
      :label="$t('ui.repeatPassword')"
    />
    <BaseCheckbox
      v-if="reg.tacUrl"
      id="reg-tac"
      v-model="user.tacAccepted"
      :label="tacLabel"
    />
    <BaseCheckbox
      v-if="reg.ppUrl"
      id="reg-pp"
      v-model="user.ppAccepted"
      :label="ppLabel"
    />
    <BaseButton
      type="submit"
      :disabled="isDisabled"
    >
      {{ buttonText }}
    </BaseButton>
  </form>
  <BaseInfobox
    v-if="feedback"
    :type="infoboxType"
    v-html="feedback"
  />
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseInfobox from '@/components/BaseInfobox.vue'
import RegistrationService from '@/services/RegistrationService.js'

export default {
  name: 'TheRegistrationForm',
  components: {
    BaseInput,
    BaseCheckbox,
    BaseButton,
    BaseInfobox
  },
  props: {
    reg: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {
        tacAccepted: 0,
        ppAccepted: 0,
        /* Optionale Adresse aus URL zum vorausfüllen */
        email: this.reg.email
      },
      isProcessing: false,
      feedback: '',
      infoboxType: ''
    }
  },
  computed: {
    showForm () {
      return !(this.feedback && this.infoboxType === 'success')
    },
    tacLabel () {
      return this.$t('ui.acceptTac', { tacUrl: this.reg.tacUrl })
    },
    ppLabel () {
      return this.$t('ui.acceptPp', { ppUrl: this.reg.ppUrl })
    },
    /* Boolean Attribut muss "null" sein, damit es entfernt wird */
    isDisabled () {
      return this.isProcessing ? '' : null
    },
    buttonText () {
      return this.isProcessing ? this.$t('ui.processing') : this.$t('ui.register')
    }
  },
  methods: {
    /* VM Funktionen */
    async register () {
      if (this.isFormValid()) {
        const creds = {
          email: this.user.email,
          pass: this.user.pass,
          tac: this.user.tacAccepted,
          pp: this.user.ppAccepted,
          regId: this.$route.params.regId,
          domain: window.location.host
        }
        this.isProcessing = true
        const response = await RegistrationService.register(creds)
        this.isProcessing = false
        this.infoboxType = response.hasError ? 'error' : 'success'

        /* In gewissen Serverantworten müssen dynamisch Werte gesetzt werden */
        const translationVars = {
          appId: this.reg.appId,
          regId: this.$route.params.regId,
          email: this.user.email,
          lang: this.$i18n.locale
        }
        this.feedback = this.$t(response.msg, translationVars)
      }
    },
    /* Interne Funktionen */
    isFormValid () {
      if (this.user.pass.length < 8) {
        this.infoboxType = 'error'
        this.feedback = this.$t('clientError.pwdTooShort')
        return false
      }
      if (this.user.pass !== this.user.passConfirm) {
        this.infoboxType = 'error'
        this.feedback = this.$t('clientError.pwdsDontMatch')
        return false
      }
      return true
    }
  }
}
</script>
